import React from 'react';

import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { withQuoteWizard } from '../components/withQuoteWizard';

import { SirGrangePage } from '../imageURLs';

import SEO from '../components/SEO';

import Post from '../components/Post/Post';
import Image from '../components/Image';
import Rating from '../components/Rating/Rating';
import CarouselSlider from '../components/Carousel/CarouselSlider';
import Compare from '../components/Compare/Compare';
import GreenCta from '../components/GreenCta';

import compareGroups from '../helpers/compareGroups';

const sliderItems = [
  {
    title: '',
    body: 'The finest leaf of all lawn varieties',
    imagePath: SirGrangePage.slides.slide1,
  },
  {
    title: '',
    body: "You don't need to mow it as often",
    imagePath: SirGrangePage.slides.slide2,
  },
  {
    title: '',
    body: "It's as practical as it is luxurious",
    imagePath: SirGrangePage.slides.slide3,
  },
];

const ratings = [
  {
    title: 'Low maintenance',
    score: 5,
  },
  {
    title: 'Shade tolerance',
    score: 5,
  },
  {
    title: 'Drought tolerance',
    score: 4,
  },
  {
    title: 'Weed and pest resistance',
    score: 5,
  },
];

const styles = theme =>
  createStyles({
    lawnProfileWrap: {
      padding: '90px 0px 0px 0px',
      backgroundColor: '#E9E9E9',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        padding: 0,
      },
    },
    partWrap: {
      marginTop: 20,
      marginBottom: 20,
      [theme.breakpoints.up(960)]: {
        marginBottom: 0,
      }
    },
    ratingWrap: {
      paddingBottom: 50,
      [theme.breakpoints.up(960)]: {
        paddingTop: 50,
      },
      [theme.breakpoints.up(1260)]: {
        paddingTop: 100,
        paddingBottom: 100,
      }
    },
    sliderWrap: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up(960)]: {
        paddingTop: 50,
        paddingBottom: 50,
      }
    },
    icon: {
      minHeight: 180,
      minWidth: 180,
      [theme.breakpoints.up(370)]: {
        minHeight: 231,
        minWidth: 231,
      },
    },
  });

class ThePage extends React.Component {
  state = {
    displayRating: false,
  };

  onRatingVisibilityChange = isVisible => {
    if (this.state.displayRating === false && isVisible === true) {
      this.setState({ displayRating: true });
    }
  };
  render() {
    const { classes, toggleQuoteComp, children } = this.props;
    return (
      <React.Fragment>
        <SEO
          title="Sir Grange - Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `varieties`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Post
          title="Sir Grange"
          subTitle="Practical luxury"
          customerName="Pam & Mark"
          customerAddress="Bribie Island"
          bodyCopy="I love how good Sir Grange looks with such little effort, we barely mow it!"
          coverImage={SirGrangePage.hero}
          profileImage={SirGrangePage.happyCustomer}
          sticky
          noSpec
          toggleQuoteComp={toggleQuoteComp}
        />
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={classes.lawnProfileWrap}
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            className={classes.ratingWrap}
            md={6}
          >
            <Grid item className={classes.partWrap} container justify="center">
              <Image
                relativePath="grange.png"
                className={classes.icon}
                imgStyle={{ width: '100%' }}
              />
            </Grid>

            <Grid item className={classes.partWrap}>
              <Typography variant="h4" align="center" component="h3">
                Lawn profile
              </Typography>
            </Grid>

            <Grid item container direction="column" alignItems="center"  alignContent="center">
              {ratings.map(rating => (
                <Rating
                  key={rating.title}
                  title={rating.title}
                  score={rating.score}
                />
              ))}
            </Grid>
          </Grid>

          <Grid 
            item
            className={classes.sliderWrap}
            md={6}
          >
            <CarouselSlider largeImage inverted items={sliderItems}  />
          </Grid>
        </Grid>

          <Grid item
            style={{ maxHeight: 750, overflow: 'hidden' }}>
            <Image relativePath={SirGrangePage.closeUp} />
        </Grid>
        <Compare compareGroups={compareGroups}
          defaultValue="sirGrange"
        />
        <GreenCta
          ctaTitle="Want to talk with a lawn expert?"
          ctaHref="tel:1800686711"
          ctaText="Call now"
          isExternalLink1={true}
          inverted
        />

        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage),true, true
);
